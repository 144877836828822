<template>
  <div class="vol-page"
       ref="main-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span class="vol-event-name">爱心榜</span>
        <img src="../../assets/volunteer/hearts.png"
             class="vol-hearts" />

        <img src="../../assets/volunteer/require.png"
             class="vol-require" />
      </div>
      <div class="vol-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active"
                aria-current="page">爱心榜</li>
          </ol>
        </nav>
        <div class="nav-tabs-scroll">
          <ul class="nav nav-tabs nav-red">
            <li class="nav-item"
                v-for="item in tabList"
                :key="item.code"
                @click="changeTab(item)">
              <button :class="[
                          'nav-link',
                          curCode == item.code ? 'active' : '',
                        ]">
                {{ item.title }}
              </button>
            </li>
          </ul>
        </div>

        <div class="list"
             v-if="curCode == 1">
          <div class="list-cell"
               v-for="(item, index) in singleRankingList"
               :key="item.member_id"
               v-if="index < 100">
            <div class="cell-left">
              <div class="circle-ranking">
                <span>{{item.ranking}}</span>
              </div>
              <img class="avatar"
                   :src="item.avatar" />
              <div class="name">{{item.nickname}}</div>
            </div>
            <div class="cell-right">
              <span class="ranking-number">{{item.score}}</span>
              <img class="crown" src="../../assets/volunteer/heart.svg" />
            </div>
          </div>
        </div>
        <!-- 地区 -->
        <div class="area-list flex-col"
             v-if="curCode == 2">
          <div class="area-title">
            <div @click="areaBack"
                 class="area-title-left">
              <i v-if="level == 1"
                 class="iconfont icon-youjiantou"></i>
              <span>{{curArea ? curArea.area_name : "河北省"}}</span>
            </div>
            <span>{{areaTotal}}</span>
          </div>
          <div class="area-cell"
               v-for="(item, index) in areaRankingList"
               :key="item.area_id"
               @click="changeArea(item)">
            <div class="flex-row-left">
              <div class="flex-row-top">
                <div class="circle-ranking">
                  <span>{{index+1}}</span>
                </div>
                <div class="name">{{item.area_name}}</div>
                <span class="total-num">{{item.count}}</span>
              </div>
              <div class="flex-row-bottom">
                <span>活动数量 {{item.event_count}}</span>
                <span>志愿者数量 {{item.volunteer_count}}</span>
              </div>
            </div>
            <i class="iconfont icon-arrow-right"></i>
          </div>
        </div>
        <!-- 活动 -->
        <div class="area-list event-list flex-col"
             v-if="curCode == 3">
          <div class="area-cell"
               v-for="(item, index) in eventRankingList"
               :key="item.event_id">
            <div class="flex-row-left">
              <div class="flex-row-top">
                <div class="circle-ranking">
                  <span>{{index+1}}</span>
                </div>
                <div class="name">{{item.title}}</div>
                <span class="total-num">{{item.total_count}}</span>
              </div>
              <div class="flex-row-bottom">
                <span>活动报名数量 {{item.apply_counts}}</span>
                <span>浏览次数 {{item.visit_count}}</span>
              </div>
            </div>
           
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { singleTopList, eventTopList, regionTopList } from "@/network/API.js";
export default {
  data() {
    return {
      level: 0,
      curCode: 1,
      tabList: [
        { code: 1, title: "志愿者榜" },
        { code: 2, title: "地区榜" },
        { code: 3, title: "优秀活动榜" },
      ],
      singleRankingList: [],
      areaRankingList: [],
      eventRankingList: [],
      curArea: null,
      areaTotal: 0,
    };
  },
  async created() {
    let data = await singleTopList()
    this.singleRankingList = data.list
    this.getAreaTop("130000")
    let list = await eventTopList()
    this.eventRankingList = list
  },

  methods: {
    changeTab(t) {
      this.curCode = t.code;
    },
    areaBack() {
      this.curArea = null
      this.level = 0
      this.getAreaTop("130000")
    },
    changeArea(item) {
      if (this.level == 1) {
        return;
      }
      this.curArea = item
      this.level = 1
      this.getAreaTop(item.area_id)
      document.documentElement.scrollTop = 0
    },
    async getAreaTop(region) {
      let data = await regionTopList({
        region: region
      })
      this.areaTotal = data.count
      this.areaRankingList = data.sub_list
    }

  },
};
</script>
<style>
.vol-hearts {
  top: -80px;
}

.vol-require {
  position: absolute;
  z-index: 3;
  right: 120px;
  top: 40px;
  width: 179px;
  height: 203px;
}

.list {
  padding: 0 30px;
}

.list-cell {
  display: flex;
  /* border-bottom: 1px solid #FEFEFE; */
  /* box-shadow: 0 2px 0 0 rgba(171, 171, 171, 0.2); */
  /* height: 130px; */
  padding: 20px 0;
  align-items: center;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}

.cell-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.cell-right {
  display: flex;
  font-size: 24px;
  color: #333333;
}

.ranking {
  width: 43px;
  height: 55px;
  left: 47px;
  flex: 0 0 43px;
}

.circle-ranking {
  background: linear-gradient(134deg, #ffb990 1%, #ff3241 100%);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 21.5px;
  left: 47px;
  text-align: center;
  margin-right: 20px;
  
}

.circle-ranking span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}

.avatar {
  width: 60px;
  flex: 0 0 60px;
  height: 60px;
  border-radius: 45px;
  left: 27px;
  margin-left: 20px;
  margin-right: 20px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-right: 20px;
}

.total-num {
  font-weight: 600;
}

.crown {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}

.ranking-number {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin: 0 5px;
}

.bottom-self {
  position: fixed;
  width: 100vw;
  height: 197px;
  background-color: #fff;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.user-avatar {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  margin-left: 45px;
  overflow: hidden;
}

.user-info {
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.user-name {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
  line-height: 1em;
}

.user-ranking {
  font-size: 24px;
  font-weight: 500;
  color: #ff3241;
  line-height: 1em;
}

.self-right {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-right: 32px;
  color: #333;
}

.self-right .small {
  font-size: 24px;
  font-weight: normal;
}

.bottom-self button {
  width: 200px !important;
  height: 80px !important;
  line-height: 80px !important;
  background: linear-gradient(113deg, #ffb990 0%, #ff3241 99%) !important;
  border-radius: 40px !important;
  margin-left: 34px !important;
  color: #fff !important;
  box-sizing: border-box;
  padding: 0;
}

.bottom-self {
  font-size: 36px;
  font-weight: bold;
  color: #222;
  line-height: 75px;
}

.tip {
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 30px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}

.ranking-date {
  padding: 30px;
  box-sizing: border-box;
  color: white;
  align-items: center;
  display: flex;
}

.ranking-date image {
  margin-left: 10px;
}

.ranking-area {
  padding: 30px;
  box-sizing: border-box;
  color: white;
  align-items: center;
  display: flex;
}

.area-list {
  overflow: hidden;
  padding: 0 30px;
}

.area-title {
  /* height: 80px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.area-title .icon-youjiantou {
  transform: rotate(180deg);
  display: inline-flex;
}

.area-title-left {
  display: flex;
  align-items: center;
}

.area-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background: linear-gradient(
    96deg,
    rgba(255, 185, 144, 0.1) 0%,
    rgba(255, 50, 65, 0.1) 100%
  );
  margin-bottom: 20px;
  border-radius: 20px;
}

.area-cell span {
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.icon-youjiantou {
  font-size: 30px;
  color: #ff3241;
  padding-left: 10px;
}

.icon-pre-page {
  padding: 10px;
}

.flex-row-top {
  display: flex;
  align-items: center;
  width: 100%;
}

.flex-row-left {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flex-row-right {
  display: flex;
  align-items: center;
}

.flex-row-bottom {
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 0 0 0 50px;
  width: 100%;
  margin-top: 10px;
}

.flex-row-bottom span {
  margin: 0 20px 0 0;
}
</style>
